import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { GenericAbortSignal } from 'axios';
import { createSelector } from 'reselect'
import { FormElementV2ResponseDto } from 'src/backend';
import { uploadFileToDocumentId } from 'src/utils/form-element/upload-file-to-ducoment-id';
import { notifyBugTracker } from 'src/utils/notify-bug-tracker';

import type { AppThunkPromise, RootState } from '../store';
import { answerFileFormElement, setOptimisticElementFileUploads } from './form-element';
import { licenseKeysSlice } from './license-keys';

interface DocumentsState {
    uploadingIds: Record<string, string>,
    formElementUploadingProgress: Record<string, number>,
}

export const documentSliceInitialSlice: DocumentsState = {
    formElementUploadingProgress: {},
    uploadingIds: {},
};

export const documentSlice = createSlice({
    name: 'documents',
    initialState: documentSliceInitialSlice,
    reducers: {
        addIdToUploadingIds(state: DocumentsState, action: PayloadAction<string>): void {
            state.uploadingIds[action.payload] = action.payload;
        },
        removeIdToUploadingIds(state: DocumentsState, action: PayloadAction<string>): void {
            delete state.uploadingIds[action.payload];
        },
        setUploadingProgress(state: DocumentsState, action: PayloadAction<{ id: string, progress: number }>): void {
            state.formElementUploadingProgress[action.payload.id] = action.payload.progress;
        },
        removeFormElementUploadingProgress(state: DocumentsState, action: PayloadAction<string>): void {
            delete state.formElementUploadingProgress[action.payload];
        }
    }
});

export const { reducer } = documentSlice;

export const uploadDocument = ({ submit, file, formElement, type, loanId, isMerged, signal }: { signal?: GenericAbortSignal, submit?: boolean, isMerged: boolean, file: File, formElement: Pick<FormElementV2ResponseDto, 'id' | 'title'>, type?: 'FormElement'; loanId: string }): AppThunkPromise<string> => async (dispatch, getState): Promise<string> => {
    try {
        const { [licenseKeysSlice.name]: { pdftronKey } } = getState();
        dispatch(documentSlice.actions.addIdToUploadingIds(formElement.id));
        dispatch(setFormElementUploadingProgress(formElement.id, 0));
        const uploadResult = await uploadFileToDocumentId({
            file,
            pdfTronKey: pdftronKey,
            loanId,
            signal,
            formElementId: formElement.id,
            name: formElement.title,
            progress: (percent) => {
                dispatch(setFormElementUploadingProgress(formElement.id, percent));
                if (percent >= 100) {
                    dispatch(setFormElementUploadingProgress(formElement.id, null));
                    dispatch(documentSlice.actions.removeIdToUploadingIds(formElement?.id));
                }
            }
        });
        dispatch(setFormElementUploadingProgress(formElement.id, null));
        if (type === 'FormElement') {
            await dispatch(answerFileFormElement({
                formElementId: formElement.id,
                documentId: uploadResult.documentId,
                loanId,
                submit,
                isMerged
            }));
        }
        dispatch(documentSlice.actions.removeIdToUploadingIds(formElement?.id));
        return uploadResult.documentId;
    } catch (err) {
        notifyBugTracker(err);
        console.error('Error uploading document', err);
        throw err;
    } finally {
        dispatch(setOptimisticElementFileUploads({
            elementId: formElement?.id,
            fileName: null
        }))
        dispatch(removeFormElementUploadingProgress(formElement?.id));
        dispatch(documentSlice.actions.removeIdToUploadingIds(formElement?.id));
    }
};

// add id to uploadingIds
export const addIdToUploadingIds = (id: string): PayloadAction<string> => documentSlice.actions.addIdToUploadingIds(id);
// remove id from uploadingIds
export const removeIdFromUploadingIds = (id: string): PayloadAction<string> => documentSlice.actions.removeIdToUploadingIds(id);

export const selectIdIsUploading = (id: string) => createSelector(
    (state: RootState) => state.documents.uploadingIds,
    (uploadingIds) => uploadingIds[id] !== undefined
);

export const setFormElementUploadingProgress = (id: string, progress: number): PayloadAction<{ id: string, progress: number }> => documentSlice.actions.setUploadingProgress({ id, progress });

export const removeFormElementUploadingProgress = (id: string): PayloadAction<string> => documentSlice.actions.removeFormElementUploadingProgress(id);

export const selectFormElementUploadingProgress = (ids: string[]) => createSelector(
    (state: RootState) => state.documents.formElementUploadingProgress,
    (formElementUploadingProgress) => {
        let progress = 0;
        // if we have multiple ids, we will calculate the average progress
        if (ids.length > 1) {
            let idsWithProgress = ids.filter(id => formElementUploadingProgress[id] !== undefined);
            progress = idsWithProgress.reduce((acc, id) => acc + (formElementUploadingProgress[id] || 0), 0) / idsWithProgress.length;
        } else {
            progress = formElementUploadingProgress[ids[0]];
        }
        if (isNaN(progress)) {
            return null;
        }

        return progress;
    }
);




export default documentSlice;
